@import "../global.css";


.section-container {
    display: grid;
    @extend %section-responsive;
}


.section {
    display: grid;
    grid-template-columns: 100%;
    width: 100vw;
    border: none;

    @media (--desktop) {
        width: 100%;
    }

    & .margin {
        display: none;
    }

    @nest &.golden {
        @media (min-width: $layoutWidth) and (--desktop) {
            grid-template-columns: 1fr var(--layoutWidth) 1fr;

            @nest & .margin {
                /* background: #171515; */
                display: initial;
            }
            @nest .home & .margin {
                /* background: #ffffff; */
            }
        }
    }
}

.margin.margin-left {
    /* background: $margin-shadow-left; */
    background-color: #0e0e0e;
}

.margin.margin-right {
    /* background: $margin-shadow-right; */
    background-color: #0e0e0e;
}

.home {
    .margin.margin-right, .margin.margin-left {
        background: unset;
    }
}

.menu-active {
    .margin.margin-left {
        background: $brand-color;
    }
    .margin.margin-right {
        background: #d5d5d5;

    }
}






.container-h1 {
    display: grid;
}


.filled-white {
    background: white;
    grid-column: 1/2;
    grid-row: 1;
}

.filled-branding {
    background: #207822;
    grid-column: 2/3;
    grid-row: 1/4;
}

.footer-container {
    background: $brand-color;
}

.systemy, .static, .news, .dystrybucja, .historia, .dotacje, .kontakt {
    .section-hero {
        @mixin layoutcalc margin-top, 12, 1;

        @media (--tablet-landscape) {
            min-height: $heroHeight;
        }
    }
}

/*
 * Wszystko co wyżej, poza systemami. W systemach wysokość nie powinna być ustawiana.
 * Nie wiem jak najlepiej to zapisać. Ł.
 */

.static, .news, .dystrybucja, .dotacje, .historia, .kontakt {
    .section-hero {
        @media (--tablet-landscape) {
            height: $heroHeight;
        }
    }
}


.section-header {
    .header-name {
        font-weight: 100;
        color: var(--collection-color);    
        text-transform: uppercase;

        p {
            @media (--tablet-landscape) {

                @mixin layoutcalc font-size, 12, 0.4;
            }
        }
    }

    .breadcrumb, .header-name{
        display: none;
    }

    .category-select{
        z-index: 500;


        .dropdown{
            position: relative;
        }

        .dropbtn-mobile {
            display: block;
            position: absolute;

            --size: calc($layoutWidth / 12 * 0.75);
            width: var(--size);
            height: var(--size);

            top: calc(-1.5 * var(--size));
            right: calc(0.5 * var(--size));

            background-color: #087a20;
            color: white;
            cursor: pointer;

            border-radius: 50%;
            /*font-size: 2rem;*/
            line-height: 1;
            border-width: 2px;
            border: 1px solid white;
            z-index:50;
            svg{
                width: 100%;
                height: auto;
                vertical-align: middle;
                .content{
                    fill: white;
                    width: 100%;
                }
            }
        }

        .dropbtn{
            display: none;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            text-transform: uppercase;
            font-weight: 200;
            font-family: 'fira sans';
            width: calc(100vw / 12 * 8);
            top: calc(-0.75 * ($layoutWidth / 12) * 1.5);
            margin-top: -50%;
            z-index: 40;


            a {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                text-align: center;
                border-top: 1px dotted #d3d3d3;
                &:hover{
                    background-color: $brand-color;
                    color: #fff;
                }
            }
        }



    }

    @media (--tablet-landscape) {
        display: grid;
        @mixin layoutcalc margin-top,12,0.75;
        @mixin layoutcalc margin-left margin-right,12,1;

        .header-name {
            grid-column: 1/2;
            align-self: center;
            grid-row: 2/3;
            margin: 0;
            display: initial;
        }

        .category-select{
            display: grid;
            grid-column: 2/3;
            grid-row: 2/3;
            justify-content: end;
            align-content: center;
            /* z-index: 999999999999 !important; */


            .dropbtn-mobile {
                display: none;
            }

            .dropbtn{
                border: none;
                background-color: $brand-color;
                display: initial;
                color: #ffffff;
                @mixin layoutcalc padding, 12, 0.15;
                min-width: 250px;
                text-transform: uppercase;
                font-weight: 200;
                font-family: 'fira sans';

                @mixin layoutcalc font-size, 12, 0.2;

                &:lang(en) {
                    @mixin layoutcalc font-size, 12, 0.16;
                }
                span {
                    @mixin layoutcalc font-size, 12, 0.12;
                }
            }

            .dropdown-content {
                max-width: 250px;
                margin-top: 0;
                width: 100%;
                top: 62px;
            }

        }

        .breadcrumb {
            grid-column: 1/3;
            display: initial;
            color: var(--collection-color);
            font-style: italic;
            font-weight: 200;

            @mixin layoutcalc margin-bottom, 12, 0.1;

            a {
                color: var(--collection-color);
                @mixin layoutcalc font-size, 12, 0.2;
            }
            
            a:hover {    
                text-decoration: none;
                border-bottom: 1px dotted;
                }
            
            
            span {

                @mixin layoutcalc font-size, 12, 0.22;
            }
        }

    }

}